<template>
    <div class="cardAssetChange">
        <div class="left-box">
            <div class="title">会员信息</div>
            <div class="tr-li card-tr">
                <div class="lable-txt">卡号：</div>
                <div class="from-input">
                    <div class="card-no"><input-pattern pattern="code" placeholder="请输入卡号或读卡" v-model="cardNo" keyBoard @keyup.enter="selectReadCard()" :maxlength="20"/><i class="iconfont icon-sousuo" @click="selectReadCard()"></i></div> 
                    <div class="read-card" @click="readCard()">读卡</div>    
                </div>
            </div>
            <div class="info-box">
                <div class="title">客户资料信息</div>
                <div class="tr-li">
                    <div class="lable-txt">会员等级：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Bestech_Card_KindName" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">客户姓名：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.MemberName" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">手机号：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Mobile" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">生日：</div>
                    <div class="from-input"><input type="text" :value="memberInfo?.Birthday" readonly/></div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">性别：</div>
                    <div class="from-input"><input type="text" :value="{0:'男',1:'女'}[memberInfo?.Gender]" readonly/></div>
                </div>
            </div>
            <div class="info-box">
                <div class="title">资产信息</div>
                <div class="tr-li">
                    <div class="lable-txt">余额：</div>
                    <div class="from-input"><input class="text-right" type="text" :value="memberInfo?.Card_Money" readonly/>
                        <p class="describe" :style="memberInfo?'':'visibility: hidden'">其中赠送金额{{memberInfo?.Gift_Money}}元</p>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">积分：</div>
                    <div class="from-input"><input class="text-right" type="text" :value="memberInfo?.Card_PilePoint" readonly/>
                    </div>
                </div>
                <div class="tr-li">
                    <div class="lable-txt">券：</div>
                    <div class="from-input ticket" @click="ticketClick()"><input class="text-right" :value="(memberInfo?.Coupons?.length||'0')+'张'" type="text" readonly/><i class="iconfont">></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="right-box">
            <div class="scroll-box">
                <div class="info-box">
                    <div class="title">卡余额调整</div>
                    <div class="tr-box"> 
                        <span class="lable-txt-1">余额：</span>
                        <label class="radio-box"><input type="radio" :value="1" v-model="isMoneyAddOrDel" name="ye" /><i class="iconfont icon-iconoption"></i>增加</label>
                        <label class="radio-box"><input type="radio" :value="0" v-model="isMoneyAddOrDel" name="ye" /><i class="iconfont icon-iconoption"></i>减少</label>
                        <div class="inlineBlock" style="width:110px;margin-right:10px"><input-pattern pattern="money" keyBoard v-model="AddMoney" placeholder="请输入调整余额数值" /></div>
                        <div class="inlineBlock">
                            <span >其中赠送：</span>
                            <div class="inlineBlock" style="width:80px"><input-pattern pattern="money" keyBoard v-model="AddZSMoney" placeholder="请输入" /></div>
                        </div>
                        
                    </div>
                </div>
                <div class="info-box">
                    <div class="title">卡积分调整</div>
                    <div class="tr-box">
                        <span class="lable-txt-1">积分：</span>
                        <label class="radio-box"><input type="radio" value="1" v-model="isPointAddOrDel" name="jf" /><i class="iconfont icon-iconoption"></i>增加</label>
                        <label class="radio-box"><input type="radio" value="0" v-model="isPointAddOrDel" name="jf" /><i class="iconfont icon-iconoption"></i>减少</label>
                        <div class="inlineBlock" style="width:110px"><input-pattern keyBoard pattern="money" v-model="AddPoint" placeholder="请输入调整积分数值" /></div>
                    </div>
                </div>
                <div class="info-box autoH">
                    <div class="title">卡券调整
                        <div class="more" @click="couponShow=true"><i class="iconfont icon-jia"></i></div>
                    </div>
                    <div class="table-box grey-table" v-table-el-height="'tableEl'">
                        <el-table ref="tableEl" border :data="tableData"  style="width: 100%;"  
                        v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                            <el-table-column prop="Tk_Name" align="left" header-align="center" label="券名称" min-width="92"></el-table-column> 
                            <el-table-column prop="number" label="数量" width="50" >
                                <template #default="scope">
                                    <div class="input-from">
                                        <input-pattern keyBoard pattern="number" :dplaces="0" :min="0"
                                        v-model="scope.row.Tk_Number" @input="editTable(scope.row)" />
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="BDate" label="开始日期" width="100" >
                                <template #default="scope">
                                    <div class="input-from">
                                        <el-date-picker class="from-date"
                                        :clearable="false"
                                        :editable="false"
                                        v-model="scope.row.BDate"
                                        type="date"
                                        placeholder=""
                                        @change="editTable(scope.row)"
                                        :default-value="new Date()">
                                        </el-date-picker>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="endDate" label="结束日期" width="100" >
                                <template #default="scope">
                                    <div class="input-from">
                                        <el-date-picker class="from-date"
                                        :clearable="false"
                                        :editable="false"
                                        v-model="scope.row.EDate"
                                        type="date"
                                        placeholder=""
                                        @change="editTable(scope.row)"
                                        :default-value="new Date()">
                                        </el-date-picker>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="券适用门店" width="80" >
                                <template #default="scope">
                                    <el-button  @click="departmentClick(scope.row)" type="text" size="small">查看</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="50"> 
                                <template #default="scope">
                                    <el-button @click="handleDelete(scope.$index,scope.row)" type="text" size="small">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="footer-box">
                <button class="btn" @click="back()">返回</button>
                <button class="btn selected" @click="cardDataAdjustment()">确定</button>
            </div>
        </div>
        <!-- 券列表 -->
        <modal-load :isShow="ticketShow">
            <ticket-model :isShow="ticketShow" @closeModel="ticketShow=false" :Coupons="memberInfo?.Coupons"></ticket-model>
        </modal-load> 
        <!-- 优惠券选择 -->
        <modal-load :isShow="couponShow">
            <coupon-select-model :isShow="couponShow" @closeModel="couponShow=false" v-on:confirm="selectCoupon"></coupon-select-model>
        </modal-load> 
        <!-- 门店选择 -->
        <modal-load :isShow="departmentShow">
            <department-select-model :isShow="departmentShow" 
            :doorAutoIds="doorRow.Door_Autoids"
            :isAllDoor="doorRow.IsAllDoor"
            @closeModel="departmentShow=false" 
            v-on:confirm="selectDepartment"></department-select-model>
        </modal-load> 
        <!-- 选择卡信息 -->
        <modal-load :isShow="cardMemberShow">
            <select-card-member-info :isShow="cardMemberShow" @closeModel="cardMemberShow=false" :data="cardMemberList" @confirm="selectCardInfo"></select-card-member-info>
        </modal-load>
    </div>
</template>

<script>
import { ticketModel,couponSelectModel,departmentSelectModel,selectCardMemberInfo} from '../model'

export default {
    name:'cardAssetChange',
    components:{ticketModel,couponSelectModel,departmentSelectModel,selectCardMemberInfo},
    data(){
        return {
            /**卡号 */
            cardNo:'',
            /**卡id */
            card_AutoID:'',
             /**会员信息 */
            memberInfo:undefined,
            /**券列表 */
            ticketShow:false,
            /**选择卡信息 */
            cardMemberShow:false,
            /**多个卡信息 */
            cardMemberList:[],
            /**是否开票 */
            isInvoicing:false,
            /**优惠券选择 */
            couponShow:false,
            /** 选择适用门店*/
            departmentShow:false,
            /**余额 减少或增加 */
            isMoneyAddOrDel:1,
            /**卡余额调整 */
            AddMoney:0,
            /** 卡余额调整 其中赠送 */
            AddZSMoney:0,
            /**卡积分 减少或增加 */
            isPointAddOrDel:1,
            /**卡积分调整 */
            AddPoint:0,
            doorRow:{},
            /**卡券调整 数据 */
            tableData:[],
            /**删除的优惠券主键集合 */
            DelCardTKAutoids:[],
        }
    },
    watch:{
        cardNo(){
            this.memberInfo=null;
        },
        isMoneyAddOrDel(){
            this.setAddZDMoney();
        },
        //调整余额数值
        AddMoney(){
            this.setAddZDMoney();
        }
    },
    methods:{
        setAddZDMoney(){//计算 余额调整 减少 时 赠送部分金额
            if(this.memberInfo && this.isMoneyAddOrDel==0){//余额调整 减少
                //计算赠送减少的金额 = 赠送金额/余额 * 减少金额
                this.AddZSMoney=Number(((this.memberInfo?.Gift_Money/this.memberInfo?.Card_Money)*this.AddMoney).toFixed(2));
            }
        },
        /**退出 */
        back(){
            this.$emit("close");
        },
        /*刷新当前页面 */
        reload() {
            this.$emit("reload");
        },
        ticketClick(){
            if(this.memberInfo){
                this.ticketShow = true;
            }
        },
        /**点击查询图标读卡   m1卡 Card_AutoID 卡id,Card_SN 内卡号 */
        selectReadCard(Card_AutoID,Card_SN){
            if(!this.cardNo && !Card_SN){
                this.$message.warning('请填写会员卡号')
                return
            }
            const loading = this.$loading({
                text: "读取会员信息中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            this.$httpAES.post("Bestech.CloudPos.GetMemberInfo",{
                User_ID:this.userInfo?.User_ID,
                DynamicNo:this.cardNo,
                Card_AutoID:Card_AutoID,
                Card_SN:Card_SN
            }).then((data)=>{
                loading.close();
                console.log('会员信息:',data)
                if(data.ResponseHeader.ResultCode==0){
                    if(data.ResponseBody.length>1){
                        this.cardMemberList=data.ResponseBody;
                        this.cardMemberShow=true;
                    }else{
                        this.selectCardInfo(data.ResponseBody[0])
                    }
                }else{
                    this.memberInfo=null;
                    this.tableData=[];
                    this.$message.error("会员信息读取失败："+data.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.memberInfo=null,
                this.$message.error('会员信息读取失败：'+e);
                console.log('会员信息读取失败：'+e);
            })
        },
        /**点击 读卡 */
        readCard(){
            this.$webBrowser.redCardM1().then((d)=>{
                if(d){
                    if(d.state==0){
                        this.card_AutoID=d.data.cardID;
                        this.cardNo=d.data.cardNo;
                        this.selectReadCard(d.data.cardID,d.data.snr);
                    }else{
                        this.$message.error('卡信息读取失败：'+d.message);
                    }
                }
            })
        },
        /**多个卡信息时选择一个 */
        selectCardInfo(data){
            this.cardMemberShow=false;
            this.cardNo=data.Card_No;
            this.$nextTick(()=>{
                this.memberInfo = data;
                    this.card_AutoID = this.memberInfo?.Bestech_Card_AutoID;
                    let coupons=[];
                    (this.memberInfo?.Coupons||[]).map(item=>{
                        let Door_Autoids=[];
                        (item.Coupon_AllowedUsing_Doors||[]).map((door)=>{
                            Door_Autoids.push(door.Door_AutoID)
                        })
                        coupons.push({
                            CardTKAutoid:item.Coupon_AutoID,//优惠券ID
                            Tk_Autoid:item.Tk_AutoID,//券类ID
                            Tk_Name:item.Coupon_Name,//优惠券名称
                            Tk_Number:1,
                            BDate:item.Begin_Date,//开始时间
                            EDate:item.End_Date,//结束时间
                            IsAllDoor:item.Is_AllDoor?1:0,//是否适用所有门店
                            Door_Autoids:Door_Autoids//券可用门店
                        })
                    });
                    this.tableData=coupons;
            })
        },
        /**选择优惠券 */
        selectCoupon(data){
            if(data){
                (data||[]).map(item=>{
                    item.Tk_Number=1;
                    item.BDate="";
                    item.EDate="";
                    item.IsAllDoor=1
                })
                this.tableData=this.tableData.concat(data);
                this.couponShow=false
            }
        },
        /**点击查看 */
        departmentClick(row){
            this.departmentShow=true;
            this.doorRow=row;
        },
        /**选择门店 信息 */
        selectDepartment(isAll,data){
            console.log(isAll,data)
            if(isAll){
                this.doorRow.IsAllDoor=isAll?1:0;
                this.doorRow.Door_Autoids=[];
            }else{
                this.doorRow.IsAllDoor=0;
                this.doorRow.Door_Autoids=data;
            }
            this.doorRow._operation="edit";
            this.departmentShow=false;
        },
        /**修改打标记 */
        editTable(row){
            row._operation="edit";
        },
        /**删除卡券 */
        handleDelete(index, row){
            if(row.CardTKAutoid) this.DelCardTKAutoids.push(row.CardTKAutoid);
            this.tableData.splice(index,1);
        },
        /**卡资产调整 */
        cardDataAdjustment(){
            if(!this.cardNo){
                 this.$message.warning('请先输入卡号');
                 return
            }
            if(!this.memberInfo){
                 this.$message.warning('请先获取会员卡数据');
                 return
            }

            if(parseFloat(this.AddZSMoney)>parseFloat(this.AddMoney)){
                 this.$message.warning('赠送金额不能大于余额！');
                 return
            }

            if(this.isMoneyAddOrDel==0){//卡余额减少时
                if(Number(this.AddMoney)>Number(this.memberInfo?.Card_Money)){
                    this.$message.warning('卡余额减少金额不能大于余额！');
                    return
                }
            }

           
            let userInfo=this.$auth.getUserInfo();

            for(let i=0;i<this.tableData.length;i++){
                if(!(this.tableData[i].Tk_Number>0)){
                    this.$message.warning(this.tableData[i].Tk_Name+":数量必须大于0");
                    return;
                }
                if(this.tableData[i].BDate==""){
                    this.$message.warning(this.tableData[i].Tk_Name+":开始日期未选择");
                    return;
                }
                if(this.tableData[i].EDate==""){
                    this.$message.warning(this.tableData[i].Tk_Name+":结束日期未选择");
                    return;
                }
                if(new Date(this.tableData[i].BDate)>new Date(this.tableData[i].EDate)){
                    this.$message.warning(this.tableData[i].Tk_Name+":开始日期不能大于结束日期");
                    return;
                }
                if(!this.tableData[i].CardTKAutoid && !this.tableData[i].IsAllDoor && (!this.tableData[i].Door_Autoids || this.tableData[i].Door_Autoids.length==0)){
                    this.$message.warning(this.tableData[i].Tk_Name+":券适用门店不能为空");
                    return;
                }
                this.tableData[i].BDate=(new Date(this.tableData[i].BDate)).Format("yyyy-MM-dd");
                this.tableData[i].EDate=(new Date(this.tableData[i].EDate)).Format("yyyy-MM-dd");
            }

            let addList=[],updateList=[];
            this.tableData.map(item=>{
                if(!item.CardTKAutoid){
                    addList.push(item);
                }else if(item._operation=="edit"){
                    updateList.push(item);
                }
            })

            

            let data={
                User_ID:userInfo?.User_ID,
                Card_AutoID:this.card_AutoID,//卡主键
                Operate_User:userInfo?.Login_Name,//绑卡操作员
                Operate_PosName:userInfo?.Department_Name, //绑卡站点
                AddMoney: this.isMoneyAddOrDel=="1"?this.AddMoney:-this.AddMoney,//卡余额调整 正数增加 负数减少
                AddZSMoney:this.isMoneyAddOrDel=="1"?this.AddZSMoney:-this.AddZSMoney,//卡余额调整 其中赠送 正数增加 负数减少
                AddPoint:this.isPointAddOrDel=="1"?this.AddPoint:-this.AddPoint,//卡积分调整 正数增加 负数减少
                DelCardTKAutoids:this.DelCardTKAutoids,//删除的优惠券主键集合
                AdjustmentAddTkInfos:addList,//新增的优惠券集合
                AdjustmentUpdateTkInfos:updateList,//修改的优惠券集合
            }

            if(data.AddMoney==0 && data.AddPoint==0 && data.DelCardTKAutoids.length==0 && data.AdjustmentAddTkInfos.length==0 && data.AdjustmentUpdateTkInfos.length==0){
                this.$message.warning('请输入调整余额或积分!');
                return;
            }

            const loading = this.$loading({
                text: "卡资产调整中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            
            console.log(JSON.stringify(data));

            this.$httpAES.post("Bestech.CloudPos.CardDataAdjustment",data).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode!=0){
                    this.$message.error("卡资产调整失败："+d.ResponseHeader.ResultDesc);
                    return;
                }
                this.$message.success("卡资产调整完成");
                this.reload();
            }).catch((e)=>{
                loading.close();
                this.$message.error('卡资产调整失败：'+e);
                console.log('卡资产调整失败：'+e);
            })
        }
    }
}
</script>

<style lang="scss">
@import "./cardAssetChange.scss";
</style>